<template>
  <v-app class="layout-bg">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-img style="height: 100px" :src="logo" :lazy-src="logo"></v-img>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="primary"></v-divider>
      <v-list dense>
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          link
          exact
          @click="navigateTo(link.to)"
        >
          <v-list-item-icon class="text--primary">
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text--primary">
            {{ link.title }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" flat app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h6 text-capitalize primary--text">
        {{ $route.name !== "UserDetails" ? pageName : user.fullname }}
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer class="white">
      <v-card-text>&copy; Havil {{ new Date().getFullYear() }}</v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "authenticated",
  data() {
    return {
      links: [
        {
          to: "/dashboard",
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
        },
        {
          to: "/users",
          title: "Users",
          icon: "mdi-account-group-outline",
        },
        {
          to: "/transactions",
          title: "Transactions",
          icon: "mdi-chart-line",
        },
        {
          to: "/logs",
          title: "Logs",
          icon: "mdi-database",
        },
        {
          to: "/sign_in",
          title: "Logout",
          icon: "mdi-power",
        },
      ],
      drawer: !!this.$vuetify.breakpoint.mdAndUp,
      logo: require("@/assets/logo.png"),
    };
  },
  computed: {
    ...mapGetters("users", ["user"]),
    pageName() {
      return this.$route.path.replace("/", "");
    },
  },
  methods: {
    ...mapActions({
      logout_user: "auth/logout_user",
    }),
    async navigateTo(url) {
      if (url === "/sign_in") {
        await this.logout();
      }
      this.$router.push({ path: url });
    },
    async logout() {
      try {
        await this.logout_user();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.layout-bg {
  background: #f4fcff;
}
</style>
